:root {
  --chart-border: #CFCFCF;
  --primary: #0075E0;
}
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d1cfcf;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.error{
  color: rgb(159 10 10);
  font-size: 10px;
}
.has-error{
  border: 0.5px solid rgb(159 10 10) !important;
  border-radius: 5px;
}
pre{
  font-family: 'Roboto', sans-serif;
  margin:0px;
}