.de-container {
    // display: flex;
    // flex-direction: column;
    // width: 1100px;
    margin: auto;
    height: 80vh;
    // @media screen and (min-width: 1024px) and (max-width: 1200px) {
    //     width: 980px;
    // }
}

.add-scenarios {
    display: flex;

    .id-li-item {
        flex-basis: auto;
        flex-grow: 1;
        position: relative;
    }

    .id-li-item:not(:last-child) {
        margin-right: 10px;
    }
}

.seperator {
    margin-top: 3em;
    background-color: #dfdddf;
    width: 1075px;
    height: 2px;
}

.de-summary {
    display: flex;
    flex-direction: column;
    height: 100%;

    .data-container {
        overflow: auto;
        height: 50vh;
        .key-value-wrapper {
            display: flex;
            .key {
                width: 100px;
                font-family: 'Roboto';
                font-size: 14px;
                color: rgba(178, 172, 172, 1);
                font-weight: 500;
            }
            .value {
                font-size: 14px;
                margin-left: 1em;
                color: rgba(84, 81, 81, 1);
                font-weight: 500;
                flex: 1;
                word-wrap: break-word;
                max-width: 300px;
            }
        }
        .key-value-wrapper:not(:last-child) {
            margin-bottom: 5px;
        }
    }
}

.de-main-container {
    flex: 1;
    border: #d3d3d3 1px solid;
    border-radius: 3px;
    overflow: auto;
    height: 100%;
    box-sizing: border-box;
}

.data-extraction-container {
    display: flex;
    flex-direction: row;
    height: 100%;
}

.search-container {
    position: relative;
    width: 30%;
    border-right: 1px solid grey;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .inner-search-container {
        padding: 10px;
        box-sizing: border-box;
        width: 100%;
    }
}

.data-list {
    overflow: auto;
    max-height: 45vh;
}

.query-container {
    margin: 10px;
    box-sizing: border-box;
    flex: 1;
    width: 65%;
    display: flex;
    flex-direction: column;
}

.button-container {
    float: right;
    padding: 10px;
    margin-top: auto;
    button {
        margin-right: 10px;
        margin-top: 0px;
    }
}

.button-container-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    float: right;
    width: 6.5em;
    position: absolute;
    right: 15.5em;
    top: 11em;
    @media screen and (min-width: 1024px) and (max-width: 1200px) {
        right: 10.5em;
        top: 12em;
    }
    @media screen and (min-width: 1440px) {
        right: 22em;
        top: 12.5em;
    }
    @media screen and (min-width: 1536px) {
        right: 25.5em;
        top: 12.5em;
    }
    @media screen and (min-width: 1680px) {
        top: 12.5em;
        right: 31.5em;
    }
    @media screen and (min-width: 1920px) {
        top: 12.5em;
        right: 40.5em;
    }
    @media screen and (min-width: 2560px) {
        top: 12.5em;
        right: 66em;
    }
}

.copy-button-container {
    margin: 10px 0px;
    overflow: auto;
    min-height: 50px;
    button {
        float: right;
        margin-right: 5px;
    }
}

.create-form {
    margin: auto;
    margin-top: 5em;
    flex: 1;
    overflow: auto;
    overflow-x: hidden;
    border: 1px solid #d3d3d3;
    border-radius: 3px;
    padding: 20px 20px;
    @media screen and (min-width: 1024px) and (max-width: 1200px) {
        width: 800px;
    }
}
.button-container-basic {
    @media screen and (min-width: 1024px) and (max-width: 1200px) {
        margin-left: -20px;
        float: right;
        position: absolute;
        right: 10%;
    }
}

.add-update-btn {
    margin-top: 2em;
    float: right;
}

.table-details {
    width: 100%;

    padding: 10px 5px;
    border-bottom: 1px solid #d3d3d3;
}
.searchdata-key {
    width: 90px;
}

.searchdata-value {
    max-width: 170px;
    padding-right: 10px;
}
.table-details-selected {
    text-align: left;
    width: 100%;
    padding: 10px 5px;
    border-bottom: 1px solid #d3d3d3;
}

.create-button {
    border-top: 1px solid #d3d3d3;
    border-bottom: 1px solid #d3d3d3;
    margin-top: 2em;
    text-align: center;
}

.create-button-active {
    border: 1px solid #d3d3d3;
    margin-top: 2em;
    text-align: center;
    background-color: #ffd4ff;
    margin-left: 2px;
    border-left: 5px solid #b23eb4;
    background-color: #ebb5ec;
}

.add-query-btn {
    float: right;
}
.data-create-update {
    overflow: auto;
    padding: 10px;
    box-sizing: border-box;
    .query-sub-container {
        // background-color: #ebb5ec;
        // border: 1px solid #e4e4e4;
        // padding: 10px;
        border-radius: 3px;
        margin-top: 55px;
        margin-bottom: 5px;
        background-color: #F5F5F5;

        .heading-container-query {
            display: flex;
            flex-direction: row;
            text-align: center;
            border: 0.5px #d3d3d3 solid;
            border-top-right-radius: 3px;
            border-top-left-radius: 3px;
            .right-seperator {
                width: 0.5px;
                height: 50px;
                padding-top: 0px;
                border-right: #d3d3d3 1px solid;
            }
        }
        .background-highlight{
            // background-color: #b23eb4;
            color:#e0e0e0;
        }
        .list-item-query {
            min-height: 45px;
            border: 0.5px solid #d3d3d3;
            border-top: 0px;
            padding:15px 10px;
            box-sizing: border-box;
            display: flex;
            font-family: Arial;
            justify-content: center;
            font-size: 13px;
        }        
        .name-column {
            width: 25%;
            text-align: center;
            // background-color: #b261b5;
        }
        .date-column {
            width: 25%;
            text-align: center;
        }
        .actions-column {
            width: 25%;
            text-align: center;
            // background-color: #d3d3d3;
            display: flex;
            cursor: pointer;
            // justify-content: center;
            .delete {
                margin-left: 10px;
                margin-right: 10px;
            }
        }
        .button-container-query {
            // background-color: #ffd4ff;
            width: 100%;
            display: flex;
            justify-content: flex-end;
            Button {
                margin-left: 5px;
            }
        }
    }
}
.confirmation-outer-container {
    border: 1px solid #d3d3d3;
    border-radius: 3px;
    padding-bottom: 5px;
    .query{
        // background-color: #b23eb4;
        padding-top: 5px;
    }
}
.queries-div-confrmation{
    width:700px;
}
.table-query-display{
   
    // width:830px;
    // margin: auto;
    max-width: 1200px !important;
    margin-top: 10px;
    border:1px solid #d3d3d3;
    padding:10px;
    border-radius: 3px;
    background-color: #F5F5F5;  
    overflow: auto;

   th{
    // border: 1px solid #000;
    min-width: 90px;
   }
}
.change-width{
    width:575px;
}
.confirmation-container {
    margin: auto;
    padding-right: -5px;
    height: 70vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
   
    .scenario-summary {
        padding: 18px;
        box-sizing: border-box;
        flex: 1;
        overflow: auto;
        .label {
            font-size: 15px;
            font-weight: 500;
        }
        .basic-details {
            overflow: auto;
            display: flex;
            flex-wrap: wrap;

            .description {
                width: 575px;
            }
            .col {
                flex: 0 0 20%;
                margin: 10px 5px;
                box-sizing: border-box;

                .content {
                    font-size: 12px;
                }
            }
        }
        .queries {
            .query-data-table {
                overflow: auto;
                overflow-x: hidden;
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                background-color: #dfdddf;
                margin-bottom: 10px;
                padding: 10px;
                box-sizing: border-box;
                .div-1{
                    display: flex;
                    flex-wrap: wrap;
                }

                .col {
                    flex: 0 0 20%;
                    margin: 10px 5px;
                    box-sizing: border-box;

                    .content {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}

.button-searchdata-table {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-align: justify;
    width: 100%;
    padding: 0px;

    :hover {
        background-color: #ebb5ec;
    }
}
.highlight {
    background-color: #ebb5ec;
    border-left: 5px solid #b261b5;
}


.quer-form-list-container{
    border: 1px solid #dfdddf;
    border-radius: 3px;
    padding: 10px;
    padding-bottom: 40px;
}

.table-query-display-thead{
    font-size: 11px;
    font-weight: 400;
    font-family: Roboto;
    border-bottom: 1px solid #000;
 
}


.req{
    margin-left: 3px;
    color:red;
}

.not-req{
    display: none;
}

.row-display{
    display:flex;
}

.margin-alignment{
    margin-top: -7px;
}