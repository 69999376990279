.navigator-container {
    display: flex;

    .top-header {
        width: 100vw;
        height: 60px;
        background-color: #fff;
        position: absolute;
        border-bottom: 1px solid #d3d3d3;
        display: flex;
        justify-content: space-between;
    }
        .logo-img {
            position: relative;
            background-color: #fff;
            height: 75%;
            margin-top: 6px;
            padding: 7px;
            box-sizing: border-box;
            margin-left: 10px;
        }
        .profile-details-div{
            display: flex;
            align-items: center;
            padding: 10px 20px;
            .profile-pic{
                width:30px;
                height:30px;
                border-radius: 25px;
                margin-right: 5px;
            }
            .profile-details{
                display: flex;
                flex-direction: column;
                font-family: 'Roboto';
                font-size: 13px;
            }
            .role{
                text-transform: capitalize;
                color:#B4B4B4;
            }
        }
        .view-dashboard{
            margin-top: 5px;
            margin-left: 10px;
        }
    .dashboard-div{
        float:right;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 100%;
        margin-right: 30px;
        h2{
            color: #706b6b;
            margin: 0px;
            display: flex;
            align-items: center;
        }
    }

    .controller-container {
        width: 90px;
        height: 99.5vh;
        overflow: auto;
        // box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
        border-right: 1px solid #d3d3d3;
        align-items: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        background-color: #fff;

        .label {
            font-size: 12px;
            color: #000000a6;
            margin-top: 4px;
            text-transform: capitalize;
        }
        .controller-link-wrapper {
            flex: 1;
            display: flex;
            align-items: center;
            width: 100%;
            box-sizing: border-box;

            .li-container {
                width: 100%;

                a {
                    text-decoration: none;

                    .li-item {
                        height: 55px;
                        justify-content: center;
                        align-items: center;
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        padding: 10px;
                        box-sizing: border-box;
                    }

                    .li-item:hover {
                        background-color: #ffd8ff;
                    }
                }
                .active .li-item {
                    background-color: #ffd8ff;
                    border-right: 3px solid #b261b5;
                }
                .active svg {
                    color: #b261b5 !important;
                    font-weight: 600;
                }

                .active .label {
                    color: #b261b5 !important;
                    font-weight: 600;
                }

                .inactive .li-item {
                    background-color: #fff;
                }
            }
        }

        .extra-conatiner {
            height: 70px;
            width: 100%;
            .extra-btn {
                width: 100%;
                justify-content: center;
                align-items: center;
                display: flex;
                flex-direction: column;
                cursor: pointer;
                padding: 10px 0px;
                background-color: #fff;
            }

            .extra-btn:hover {
                box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.2);
                background-color: #ffd8ff;
                .label,
                svg {
                    color: black !important;
                }
            }

            .cache {
                background-color: #e749495e;
            }
            .cache:hover {
                background-color: #ed343482;
                .label,
                svg {
                    color: white !important;
                }
            }
        }
    }

    .component-container {
        margin-top: 60px;
        flex: 1;
        overflow-y: hidden;
    }
}
