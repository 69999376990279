.name-desc-column{
    display: flex;
}

.name-desc-div{
    text-align: left;
    .desc{
        font-weight: 400;
        font-size: 11px;
    }
}

.action-column-list{
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.ctrl-btn-list{
    border-radius: 50%;
    margin-right: 10px;
    height: 45px;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.execute-item{
    background: #b261b5;
    color: #FFF;
}

.delete-list{
    margin-right: 20px;
}


.date{
    font-size: 12px;
    color:#919191
}