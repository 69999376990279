.audit-trails {
    padding: 10px;
   

    .heading-container-trail {
        display: flex;
        flex-direction: row;
        text-align: center;
        border: 0.5px #d3d3d3 solid;
        border-left: 0px;
        border-right: 0px;
        background-color: #f0f0f0;
    }
    .trail-container {
       

        .trails-heading-column {
            width: 150px;
            @media screen and (min-width: 1440px) {
                width:180px;
            }
            @media screen and (min-width: 1530px) {
                width:195px;
            }
            @media screen and (min-width: 1680px) {
                width:13vw;
            }
        }
        .timeStamp {
           
            margin-left: -10px;
            width: 175px;
            @media screen and (min-width: 1440px) {
                width:190px;
            }
            @media screen and (min-width: 1530px) {
                width:205px;
            }
            @media screen and (min-width: 1680px) {
                width:13vw;
            }
            
        }
        .trail-item {
            border-bottom: 1px solid #d3d3d3;
            margin-bottom: 10px;
            padding: 20px 0px;
            
            font-size: 12px;
            display: flex;
            flex-direction: column;
           

            .details-section {
                display: flex;
                flex-direction: row;
                padding-left: -5px;

                .value {
                    width: 150px;
                    @media screen and (min-width: 1440px) {
                        width:180px;
                    }
                    @media screen and (min-width: 1530px) {
                        width:195px;
                    }
                    @media screen and (min-width: 1680px) {
                        width:13vw;
                    }
                    text-align: center;
                }
                .timestamp {
                    padding-left: 5px;
                    width: 170px;
                    @media screen and (min-width: 1440px) {
                        width:190px;
                    }
                    @media screen and (min-width: 1530px) {
                        width:205px;
                    }
                    @media screen and (min-width: 1680px) {
                        width:13vw;
                    }
                
                }
                
            }

            .diff-btn {
                align-items: center;
                display: flex;
            }
        }

        .success {
            background: rgba(210, 252, 144, 0.5);
        }

        .failure {
            background: rgba(252, 148, 144, 0.5);
        }
        .div-error-msg {
            background-color: #f1f1f1;
            border: 1px solid #bdbdbd;
            border-radius: 3px;
            padding: 10px 20px;
            margin: 20px 20px 0px 20px;
            font-size: 14px;
            display: flex;
            // max-width: 850px;
            width: 70vw;
            @media screen and (min-width: 1680px) {
                max-width: 75vw;
            }
            .head-error {
                width: 10vw;
                font-weight: 600;
            }
        }

        .data-error-msg {
            font-size: 13px;
            padding-left: 5px;
            width: 60vw;
            @media screen and (min-width: 1680px) {
                width: 65vw;
            }
        }

        .view-button {
            padding: 0px 5px;
            // margin-left: 50px;
            margin-top: -10px;
        }
    }
    .no-rows{
        text-align: center;
        height:40px;
        margin-top: 20px;
    }
}
.status {
    padding: 8px 5px;
    border-radius: 20px;
    width:80px;
    margin: auto;
    font-size: 14px;
}
.green {
    background-color: #d5fdde;
    color: green;
}
.red {
    background-color: #FFE7E7;
    color: #CA0000;
}