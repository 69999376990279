.flash-message{
    position: fixed;
    background-color: #3f3737;
    color: white;
    padding: 10px;
    max-width: 150px;
    top: 20%;
    right: 20%;
    border-radius: 5px;
    animation: fadeIn 2s;
    z-index: 99999;
}

.hide-pop-up {
    animation: fadeOut 1s;
}

@keyframes fadeIn { 
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut { 
    0% {
      opacity: 1;
    }
  
    100% {
      opacity: 0;
    }
  }