.fix-details {
    padding: 10px;
    h2{
        color: #3b2828;
    }
    .scenario-id {
        h4 {
            margin: 5px 0px;
        }

        p {
            margin: 5px 0px;
            font-size: 12px;
            font-weight: 300;
        }
    }

    .data-diff-container {
        margin-top: 10px;

        .fix-diff-container {
            background: #d2cad378;
            padding: 20px;
            margin-bottom: 20px;
        }
    }
    .external-link{
        overflow: auto;
        box-sizing: border-box;
        border: 0.5px solid #c3c3c3;
        background: #f4f4f4;
        margin-bottom: 20px;
        .head-section{
            padding: 10px 20px;
            border-bottom: 1px solid;
            background: #e9e9e9;
            border-color: #d1caca;
            font-size: 16px;
            font-weight: 700;
        }
        .ext-content{
            padding: 10px;
            overflow: auto;
            .message{
                font-weight: 400;
            }
            .link{
                font-weight: 700;
                color: grey;
                margin: 5px 0px;
            }
            button{
                float: right;
            }
        }
    }
}