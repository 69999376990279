.execute-investigation {
    display: flex;
    height: 100%;

    .execute-investigation-form-section {
        flex: 1;
            overflow: auto;
            display: flex;
            flex-direction: column;
        .investigation-details {
            overflow: auto;
            padding: 10px;
            flex: 1;
            h2{
                color: #3b2828;
            }
            .external-link{
                overflow: auto;
                box-sizing: border-box;
                border: 0.5px solid #c3c3c3;
                background: #f4f4f4;
                .head-section{
                    padding: 10px 20px;
                    border-bottom: 1px solid;
                    background: #e9e9e9;
                    border-color: #d1caca;
                    font-size: 16px;
                    font-weight: 700;
                }
                .ext-content{
                    padding: 10px;
                    overflow: auto;
                    .message{
                        font-weight: 400;
                    }
                    .link{
                        font-weight: 700;
                        color: grey;
                        margin: 5px 0px;
                    }
                    button{
                        float: right;
                    }
                }
            }
            .query-meta {
                font-size: 11px;
                .section{
                    margin-bottom: 10px;
                    label{
                        color: rgb(18, 1, 37);
                        font-weight: 700;
                    }
                    .content{
                        margin-top: 5px;
                    }
                }
            }
        }
    }
}