.ag-theme-alpine {
    --ag-header-height: 20px;
    --ag-header-foreground-color: white;
    --ag-header-background-color: #323134;
    --ag-header-cell-hover-background-color: #464448;
    /* disable all borders */
    --ag-borders: none;
    /* then add back a border between rows */
    --ag-borders-row: dashed 1px;
    --ag-row-border-color: rgb(107, 106, 106);

    .ag-root-wrapper {
        border: none;
    }
}

.dynamic-grid-container {
    font-size: 12px;
    margin: 20px 0;
    background: rgba(251, 252, 252, 0.331);
    border: 1px solid rgba(128, 128, 128, 0.4196078431);
    position: relative;
    border-radius: 3px;
    
    .blur-theme {
        opacity: 0.2;
        pointer-events: none;
    }
    .loading {
        opacity: 1;
        z-index: 1000;
        background-color: #fff;
        color: black;
        font-weight: 500;
        font-size: 14px;
        text-align: center;
        width: fit-content;
        position: absolute;
        margin: 10%;
        margin-left: 50%;
        height: 10px;
    }
    .dynamic-grid {
        height: 100px;
        font-family: 'Roboto';

        .dg-row {
            border-bottom: 1px solid;
        }
        .index {
            background-color: rgb(169 175 179 / 70%);
            text-align: center;
        }
        .index-header {
            background-color: rgb(4 4 4 / 70%);
            border-right: 1px solid white;
        }
    }
    .head-section {
        display: flex;
        padding: 10px 20px;
        border-bottom: 1px solid;
        background: #e9e9e9;
        border-color: #d1caca;
        .title-section {
            flex: 1;
            display: flex;
            align-items: center;
            p {
                margin-top: 0px;
                margin-bottom: 0px;
                color: #272424;
                font-size: 16px;
                font-weight: 700;
                span {
                    font-size: 11px;
                    font-weight: 500;
                    color: grey;
                }
            }
        }
        .select-data-container {
            .select-data {
                min-width: 250px;
                margin-bottom: 0px;
                margin-right: 10px;
            }
        }
        .save-snapshot {
            margin-right: 10px;
            box-shadow: none;
        }
        .controller {
            overflow: auto;
            box-sizing: border-box;
            display: flex;
            cursor: pointer;
            display: flex;
            align-items: center;
            height: 35px;
            .icon-btn {
                    display: flex;
                    width: 35px;
                    height: 100%;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid;
                    box-sizing: border-box;
                    margin-right: 7px;
                    margin-left: 0px;
                    border-radius: 3px;
            }
        }
    }
    .inner-content {
        padding: 20px;
        .details-section{
            height: auto;
            max-height: 0px;
            transition: 0.3s;
            overflow: auto;
        }
    }
    .red {
        background-color: rgb(238 8 8 / 70%);
    }
    .blue {
        background-color: rgb(2 62 101 / 70%);
    }
    .light-red {
        background-color: rgb(238 8 8 / 39%);
    }
    .light-blue {
        background-color: rgb(2 62 101 / 30%);
    }
}
.empty-d-grid{
    font-weight: 500;
    color: #a2a2a2;
    text-align: center;
    border: 1px solid #dcd6d673;
    padding: 7px;
    background: #0483040a;
}

.details-controller{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    border: 1px solid black;
    box-sizing: border-box;
    padding: 0px 10px;
    border-radius: 3px;
    min-width: 120px;
    .details-info{
        margin-right: 5px;
    }
}
