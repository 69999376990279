.login {
    display: flex;
    height: 100vh;

    .left-half {
        width: 50vw;
        display: flex;
        flex-direction: column;
        align-items: center;

        .logo {
            width: 250px;
            margin: 55px 0px;
            margin-top: 90px;
        }

        .logo-img{
            width:100%;
        }
        .tag {
            font-size: 32px;
            font-weight: 700;
            margin: 20px 0px;
            span{
                font-size: 30px;
            }
        }

        .message {
            font-size: 16px;
            margin-top: 80px;
            margin-bottom: 30px;
        }

        .google-login {
            border: 1px solid;
            padding: 10px 40px;
            border-radius: 5px;
            cursor: pointer;
            display: flex;
            background-color: white;

            svg {
                margin-right: 10px;
            }

            .label {
                display: flex;
                align-items: center;
                flex: 1;
                font-size: 14px;
                font-weight: 500;
            }
        }
        .google-login:hover{
            box-shadow: 4px 4px 4px 0px rgb(0 0 0 / 10%);
        }

        .login-error {
            margin-top: 10px;
        }

        .pattern {
            position: absolute;
            bottom: 0px;
            width: 50vw;
            z-index: -99;

            .copy-right {
                display: none;
            }
        }

    }

    .right-half {
        display: flex;
        align-items: center;
        flex-direction: column;

        svg {
            width: 50vw;
            flex: 1;
            margin-top: 90px;
        }

        .copy-right {
            height: 10%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 15px;
            color: #4f739c;
        }
    }
}

@media only screen and (max-width: 600px) {
    .login {
        display: block;

        .left-half {
            width: 100vw;
            .message {
                font-size: 14px;
            }
            .pattern {
                width: 100vw;
                position: relative;

                .copy-right {
                    position: absolute;
                    bottom: 0px;
                    display: flex;
                    justify-content: center;
                    width: 100vw;
                    font-size: 14px;
                    color: #4f739c;
                }
            }

            .tag {
                font-size: 20px;
            }
        }

        .right-half {
            display: none;
        }
    }
}