.basic-container {
    border: 1px solid #d3d3d3;
    padding: 15px 25px;
    border-radius: 3px;
    .split-row {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .button-div {
        overflow: auto;
    }
}
.stage2-conatiner {
    height: 95%;
    display: flex;
    flex-direction: column;
    border: 2px solid #DCDCDC;
    padding: 25px;
    box-sizing: border-box;
    border-radius: 3px;

    .scenario-ds-container {
        flex: 1;
        overflow: auto;

        .ds-config-section {
            .map-ds-scenario {
                margin: 10px 0px;
                .head-row{
                    display: flex;
                    margin-bottom: 10px;
                
                    .title {
                        flex: 1;
                        font-weight: 500;
                    }
                    .remove{
                        margin-right: 30px;
                        cursor: pointer;
                    }
                }

                .queries {
                    padding: 5px 30px;

                    .query-item {
                        display: flex;
                        width: 100%;
                        align-items: center;
                        margin-bottom: 15px;
                        .required{
                            color:red;
                            margin-left: 5px;
                        }
                        .not-required{
                            display: none;
                        }
                        .query-name {
                            width: 35%;
                            display: flex;
                            flex-direction: row;
                        }

                        .connector {
                            margin: 0px 15px
                        }

                        .ds-select {
                            flex: 1;
                            .select-wrapper{
                                margin-bottom: 0px;
                            }
                        }
                    }
                }
            }
        }
    }

    .button-container {
        overflow: auto;
    }

}

.stage-3-confirm{
    height: 95%;
    display: flex;
    flex-direction: column;
    border: 2px solid #DCDCDC;
    padding: 40px 20px 20px 40px;
    box-sizing: border-box;
    border-radius: 3px;
    .summary-section{
        flex: 1;
        overflow: auto;
        .data-row{
            display: flex;
            margin-bottom: 15px;
            .label{
                width: 17%;
                color: #464A53;
                font-weight: 600;
                font-size: 15px;
            }
            .value{
                font-weight: 500;
                font-size: 13px;
                flex: 1;
                .scenario-section{
                    margin-bottom: 30px;
                    .scenario-title{
                        color:rgb(0, 0, 0, 0.85);
                        // width: 212px;
                        height: 19px;
                        font-weight: 900;
                        font-size: 13px;
                    }
                    .query-list{
                        margin: 7px 0px;
                        .head{
                            font-weight: 600 !important;
                            font-size: 13px !important;
                            color: #464A53;
                            margin: 15px 0px;
                        }
                        .query-section{
                            display: flex;
                            margin: 5px 0px;
                            font-weight: 500;
                            font-size: 13px;
                            margin: 15px 0px;
                            .query-name{
                                width: 30%;
                                margin-right: 40px;
                                word-break: break-word;
                            }
                        }
                    }
                }
            }
        }
    }

    .button-container {
        overflow: auto;
    }
}

.ds-query-container {
    margin-left: 25%;
    display: flex;
    flex-direction: row;

    .query-td {
        width: 15em;
        
    }
    .datasource-td {
        width: 20em;
    }
}
