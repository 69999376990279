.select-wrapper {
    margin-bottom: 12px;

    .label {
        font-size: 12px;
        font-weight: 500;
        color: #464343;
        // display: block;
        margin: 4px 0px;
        display: flex;
        flex-direction: row;
    }
    .required::after {
        content: ' *';
        color: red;
    }

    .input-container {
        width: 100%;
        .input > div::before {
            border: none !important;
        }
        .input > div::after {
            border: none !important;
        }
        .input > div {
            width: 100%;
            box-sizing: border-box;
            border-radius: 5px;
            padding: 5px;
            outline: none;
            font-size: 12px;
            background: #ffffff00;
            border: 0.5px solid rgb(0 0 0 / 25%);
            height:auto;
            input{
                padding: 3px;
            }
        }
    }
}
.type2 {
    display: flex;
    .label {
        width: 120px;
        display: flex;
        align-items: center;
    }
}

.drop-down {
    width: 100%;
    z-index: 1;
    position: absolute;
    margin: 0;
    padding: 10px;
    list-style: none;
    background: white;
    max-height: 250px;
    border: 0.5px solid rgb(0 0 0 / 25%);
    border-radius: 5px;
    box-sizing: border-box;
    overflow: auto;
}

.inner-container {
    width: 100%;
    display: flex;
    flex-direction: column;

    :disabled{
        cursor: not-allowed;
    }
}
