.create-datasource{
    overflow: auto;
    padding: 10px;
    .create-datasource-btn{
        position: absolute;
        top:6em;
        right:2.5em;
       
    }
    .create-datasource-form{
        padding: 10px;
        position: relative;
        box-sizing: border-box;
    .credential-container{
        margin-top: 10px;
        padding: 20px;
        background: #f8e5f8;
        border-radius: 5px;
        box-shadow: 4px 4px 4px 0px rgb(0 0 0 / 10%);
        h4{
            margin-top: 0px;
        }
    }
}
}