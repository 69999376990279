.query-response-section{
   
    box-sizing: border-box;
    width: 97%;
    margin: auto;
    margin-top: 20px;
    border-radius: 5px;
    h4{
        margin: 0px;
        color: rgb(56, 55, 55);
    }
    .transparent-div{
        width: 88vw;
        height: 100%;
        position: absolute;
        left:20;
        top:20;
        background-color: #d3d3d3;
        opacity: 0.3;
        z-index: 1000;
    }
    .query-response-item{
        .title{
            font-weight: 500;
            margin-bottom: 5px;
        }
        .query{
            font-weight: 300;
            margin-bottom: 5px;
        }
        .multi-container{
            .multi-table-meta{
                padding: 20px;
            }
            .dynamic-grid-container{
                margin-bottom: 20px;
            }
        }
                .query-meta {
                    font-size: 11px;
                    .section{
                        margin-bottom: 10px;
                        label{
                            color: rgb(18, 1, 37);
                            font-weight: 700;
                        }
                        .content{
                            margin-top: 5px;
                        }
                    }
                }
    }
}

.save-snapshot{
    .summary{
        padding: 10px;
        background: #e7e0e0;
        border-radius: 5px;
        margin: 15px 0px;
        .details{
            margin: 5px 0px;
            .label{
                font-weight: 700;
                color: #464343;
            }
        }
    }
    .save-field{
        input{
            margin-top: 5px;
        }
    }
}