.loader{
    position: fixed;
    background: #0000008e;
    height: 100vh;
    width: 100vw;
    z-index: 2000;
    display: flex;
    align-items: center;
    justify-content: center;
    .spinner {
        animation: spin infinite 3s linear;
    
      }
    
    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
    
}