.text-field{
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;

    label {
        font-size: 12px;
        min-width: 120px;
        display: flex;
        align-items: center;
        font-weight: 500;
        color: #464343;
        margin: 4px 0px;
    }
    .required::after {
        content:" *";
        color: red;
      }
    .input-wrapper{
        width: 100%;
        textarea {
            font-family: Arial;
            width: 100%;
            box-sizing: border-box;
            border-color: rgb(0 0 0 / 25%);
            border-radius: 5px;
            border-width: 0.5px;
            padding: 5px;
            background: #ffffff00;
           height: 100px;
            outline: none;
            font-size: 12px;
            font-family: Arial;
        }
    }
}