.row-dark-red{
    // background-color: rgb(247 123 123) !important;
    background: rgba(245, 118, 114, 0.687) !important;
}
.row-light-red{
    background: rgba(245, 119, 114, 0.5) !important;
    // background: rgba(245, 119, 114, 0.60625) !important;
}
.row-dark-green{
    // background-color: #7be778!important;
    background: rgba(170, 219, 126, 0.49375) !important;
    
}
.row-light-green{
    // background: rgba(214, 248, 183, 0.375) !important;
    background-color: #c4fdd1 !important;
    
}
.row-red-border{
    border-color: red !important;
}
.row-green-border{
    border-color: green !important;
}