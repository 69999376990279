.configure-color-schema {
    .title {
        font-size: 14px;
        font-weight: 600;
        color: #464343;
        margin: 10px 0px !important;
    }

    .title-container{
        display: flex;
        justify-content: space-between;
        padding: 10px;
    }

    .create-color-scheme {
        background:none;
        padding: 20px;
        box-sizing: border-box;
        border-radius: 5px;
        margin-bottom: 10px;
        overflow: auto;
        overflow-x: hidden;
        border: 1px solid #f3e8e8;
        border-radius: 5px;
        .basic-config {
            display: flex;
            justify-content: space-evenly;

            div:not(:last-child) {
                margin-right: 10px;
            }

            div {
                .label {
                    width: 60px;
                }
            }
        }

        .value-based-color-selector {
            overflow: auto;
            padding: 10px;
             background: none;
            border-radius: 5px;
            margin-bottom: 10px;
            box-shadow: 4px 4px 4px 0px rgb(0 0 0 / 10%);

            .head-row,
            .list-row {
                display: flex;
                width: 100%;

                .col-1 {
                    width: 31%;
                    padding: 5px;
                    box-sizing: border-box;
                    margin-bottom: 0px;
                }

                .col-2 {
                    width: 7%;
                    padding: 5px;
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                }
            }
        }

        .add-config-btn {
            float: right;
            background: none;
        }
        
    }
    .field-color{
        margin-top: 6px;
    }
    .summary-color-scheme {
        background: #F5F5F5;
        padding: 20px;
        box-sizing: border-box;
        border-radius: 5px;
        margin-bottom: 10px;
        overflow: auto;
        border: 1px solid #f3e8e8;
        box-shadow: 4px 4px 4px 0px rgb(0 0 0 / 10%);
        

        .row {
            display: flex;
            width: 100%;

            .value {
                font-size: 14px;
                font-weight: 500;
                margin: 2px 0px;
            }

            .label {
                font-size: 14px;
                font-weight: bold;
                margin: 2px 0px;
                width: 50px;
            }
        }

        .color-scheme {
            margin-top: 10px;
            border: 1px solid #f3e8e8;
            border-radius: 5px;
            box-shadow: 4px 4px 4px 0px rgb(0 0 0 / 10%);

            .t-head,
            .t-row {
                display: flex;

                .col-1 {
                    width: 33.3%;
                    padding: 10px;
                }
            }

            .t-head {
                .col-1 {
                    font-weight: 900;
                }
            }
        }

        .controller {
            margin-top: 12px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            // background-color: aqua;
            width:17vw;
            float: right;
        }
    }
}