
.cls-1 {
    fill-rule: evenodd;
    fill: url(#linear-gradient);
}

.cls-2 {
    font-size: 120.72px;
    fill: #b761af;
    font-family: Oceanwide-Semibold, Oceanwide;
    font-weight: 600;
}

.cls-3 {
    letter-spacing: -0.04em;
}