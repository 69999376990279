.container{
    padding: 25px;
    justify-content:right;
}

#alert-dialog-title{
    padding-left: 35px;
    height: 15px;
}

.dialog-container{
    width: 300px;
    padding-bottom: 25px;
    padding-right: 25px;
    padding-left: 15px;
    text-align: left;
}

#alert-dialog-description{
    text-align: left;
}