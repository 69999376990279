.modal {
    position: fixed;
    background: rgb(0 0 0 / 88%);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 999;
    .fullSize {
            height: 100vh;
            width: 100vw;
    
            .modal-container {
                max-width: 75%;
                margin: auto;
                margin-top: 40px;
            }
        }
    .inner {
        background-color: white;
        min-width: 70vw;
        border-radius: 5px;
        position: relative;
        padding: 30px;

        .modal-container {
            position: relative;

            .close {
                width: 30px;
                height: 30px;
                border-radius: 15px;
                background-color: #d3d3d3;
                position: absolute;
                cursor: pointer;
                right: 0px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .content{
                h1{
                    margin-top: 0px;
                }
                .children-modal{
                    max-height: 85vh;
                    overflow: auto;
                }
                .submit {
                        overflow: auto;
                
                        button {
                            float: right;
                            margin: 7px;
                        }
                    }
                }
        }
    }
}
