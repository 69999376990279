.info-tag{
    display: flex;
    flex-direction: row;
    width: max-content;
    justify-content: center;
    align-items: center;
    .required::after {
        content:" *";
        color: red;
      }

}

.info{
    display: flex;
    margin-left: 20px;
    background-color: #bee3f8 ;
    border-left: 3px solid #0692e4;
    padding: 3px 6px;
    border-radius: 3px;
}

.warning{
    display: flex;
    margin-left: 20px;
    background-color: #fde8c9 ;
    border-left: 3px solid #ff9900;
    padding: 3px 6px;
    border-radius: 3px;
}

.info-error{
    display: flex;
    margin-left: 20px;
    background-color: #ffdbdb ;
    border-left: 3px solid #fa5050;
    padding: 3px 6px;
    border-radius: 3px;
}