.exception-details{
    padding: 10px;
    // overflow: auto;
    height: 100%;
    .detail-audit-section{
        display: flex;
        .title-description{
            flex: 1;
            h4{
                margin: 0px;
                color: rgb(56, 55, 55);
            }
            p{
                margin: 5px 0px;
                font-size: 12px;
                font-weight: 300;            
            }
        }
        .audit-link{
            display: flex;
            align-items: center;
        }

    }
    .query-data-section{
        margin-top: 10px;
        overflow: auto;
        padding: 20px;
        box-sizing: border-box;
        background: aliceblue;
        border-radius: 5px;
        box-shadow: 4px 4px 4px 0px rgb(0 0 0 / 10%);
        display: none;
        h4{
            margin: 0px;
            color: rgb(56, 55, 55);
        }
        .display--execute-query{
            padding: 0px 10px;
            .query-item{
                margin-top: 10px;
                font-size: 12px;
                .title{
                    font-weight: 500;
                }
                .query-api{
                    font-weight: 300;
                    margin-top: 5px;
                }
            }
        }
    }
    
}