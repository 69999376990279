.button {
    border-radius: 5px;
    font-family: 'Roboto';
    padding: 0px 5px;
    font-size: 12px;
    cursor: pointer;
    border: 0px;
    min-width: 75px;
    height: 35px;
}
button[data-title]:hover {
    color: #b261b5;
}
button:disabled {
    color: #d3d3d3;
}
[data-title]:after {
    content: attr(data-title);
    background-color: #FFF;
    color: #111;
    font-size: 12px;
    position: absolute;
    left: 100%;
    white-space: nowrap;
    opacity: 0;
    z-index: 99999;
    top: 0px;
    left: 0px;
    visibility: hidden;
}
[data-title]:hover:after {
    opacity: 1;
    transition: all 0.1s ease 0.5s;
    visibility: visible;
}
[data-title] {
    position: relative;
}

.primary {
    background: #b261b5;
    padding: 0px 15px;
    color: #fff;
}
.primary-outlined {
    background: transparent;
    border: #b261b5 1px solid;
    color: #b261b5;
    padding: 0px 5px;
}

.primary-not-outlined {
    background: transparent;
    color: #b261b5;
    padding: 0px 8px;
}

.secondary {
    background: rgba(82, 139, 158, 1);
    box-shadow: 4px 4px 4px 0px rgb(0 0 0 / 20%);
    color: rgba(200, 194, 194, 1);
}

.teriary {
    background: rgba(180, 237, 189, 0.675);
    box-shadow: 4px 4px 4px 0px rgb(0 0 0 / 10%);
    font-size: 10px;
    padding: 5px 10px;
    min-width: 60px;
    margin-left: 10px;
}
.red-theme {
    background: rgba(243, 182, 173, 0.675);
    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 4px 4px 4px 0px rgb(0 0 0 / 10%);
    font-size: 10px;
    padding: 5px;
    min-width: 60px;
    margin-left: 10px;
}
.green-theme {
    background: rgb(176 211 120 / 70%);
    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 1);
}
.small-green-theme {
    background: rgb(176 211 120 / 70%);
    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 1);
    height: 20px;
}

.black-outlined{
    background: transparent;
    border: #000 1px solid;
    color: #000;
    padding: 0px 5px;
}

.icon-label {
    border: 1px solid #d3d3d3;
    min-width: none;
    width: 35px;
    height: 35px;
    font-size: 16px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.disabled-btn {
    color: #d3d3d3;
    border-color: #d3d3d3;
    cursor: not-allowed;
}
