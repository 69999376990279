.console-dashboard {
    padding: 12px 0px 0px 0px;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    .header-section{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0px 15px 0px 5px;
    }
    .toggle-component{
        margin-top: 10px;
        display: flex;
    }
    .default{
        background-color: #FFF;
        color: #d3d3d3;
    }
    .selected{
        background-color: #b261b5;
        color:#FFF;
    }
    .refresh-button{
        margin-left: 10px;
         margin-right: 10px;
     }
    .ctrl-btn {
        border-radius: 50%;
        height: 35px;
        width: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .execute {
        background: #b261b5;
        color: #fff;
        @media screen and (min-width:1920px) {
            margin-left: 45%;
        }
        margin-left: 42%;
        text-align: center;
    }
    .updated-time-div{
        display: flex;
        // background-color: #00af27;
        flex-direction: column;
    }
    .header {
        font-size: 15px;
        width: 78vw;
        overflow: auto;
        margin-bottom: 15px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        @media screen and (min-width:1440px) {
            width: 80vw;
        }
        @media screen and (min-width:1680px) {
            width: 83vw;
        }
        @media screen and (min-width:1920px) {
            width: 85vw;
        }
        
        .left-component {
            float: left;
            display: flex;
            align-items: center;
            height: 100%;
            width: 100%;
            
            .action-button-container {
                display: flex;
                margin-left: 10px;

                button {
                    margin: 0px 5px;
                }
            }

            .execute-delete {
                margin-left: 20px;
                width:300px;
            }
            .selection-div {
                display: flex;

                .dashboard-div {
                    margin-right: 10px;
                }
            }
            .viewer-snapshot-div{
              margin-left: auto;
            }
            .sub-div-snapshot{
                margin-top: 10px;
            }
        }

        .right-component {
            float: right;
            display: flex;
            align-items: center;
           
            .create-scenario {
                margin-top: 10px;
            }
        }
        .sub-header{
            margin-top: 5px;
            display: flex;
            .snapshot-label{
                display: flex;
                color:#464A53;
                font-size: 13px;
                
            }
        }
    }
    


    .db-display {
        flex: 1;
        // overflow: auto;
        padding: 10px;
        box-sizing: border-box;

    }

    .summary-item {
        min-height: 76px;
        border-radius: 5px;
        margin-bottom: 10px;
        padding: 10px 15px;
        box-sizing: border-box;
        border: 0.5px solid #b9b1b1;
        cursor: pointer;

        .title {
            font-family: 'Roboto';
            font-size: 18px;
            font-weight: 700;
            margin-left: 10px;
            margin: 10px;
            color: darkslategrey;
        }

        .description {
            font-size: 10px;
            font-weight: 300;
        }

        .query-card {
            overflow: auto;
            display: flex;
            flex-wrap: wrap;

            .card {
                width: 290px;
                min-height: 139px;
                border-radius: 5px;
                margin: 10px;
                float: left;
                background: rgba(0, 197, 43, 0.2);
                border: 1px solid #00c52b;
                padding: 13px 20px;
                color: #00af27;

                .name {
                    font-weight: 600;
                    font-size: 13px;
                    line-height: 24px;
                    margin-bottom: 14px;
                    word-break: break-word;
                    color: #737373;
                }

                .section {
                    justify-content: center;
                    display: flex;
                    flex-direction: column;

                    .value {
                        font-weight: 600;
                        font-size: 36px;
                        line-height: 24px;
                        margin-bottom: 9px;
                    }

                    .type {
                        font-weight: 600;
                        font-size: 15px;
                        line-height: 24px;
                        margin-bottom: 7px;
                        color: #202020;
                    }
                }

                .updated-time {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 24px;
                    display: flex;
                    align-items: center;
                    color: #202020;
                    margin: 0px;

                    span {
                        margin-left: 3px;
                    }
                }
            }

            .alert {
                background: rgba(178, 97, 181, 0.2);
                border: 1px solid #b261b5;
                color: #b261b5;
            }
        }
    }
}

.empty-db {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;

    p {
        font-size: 26px;
        color: #8080805e;
        font-weight: 500;
    }
}

.confirm-Input{
    label{
        font-size: 15px;
    }
    input{
        width:75px;
    }
}
