.execute {
    position: relative;
    background: #FFFFFF;
    border: 1px solid #B4B4B4;
    border-radius: 3px;

    .form-wrapper {
        overflow: hidden;
        height: 100%;
        transition: 0.5s;

        .head-section {
            display: flex;
            padding: 10px;
            box-sizing: border-box;
            border-bottom: 1px solid #B4B4B4;

            h2 {
                margin: 0px;
                flex: 1;
            }

            .collapse-btn {
                cursor: pointer;
                font-weight: 500;
            }
        }

        .form-content {
            display: flex;
            .left-section{
                min-width: 30%;
            }
            .form-input {
                flex: 1;
                .dynamic-form{
                    background: none;
                    box-shadow: unset;
                }
            }
            .proxy-container{
                border-bottom: 0.5px solid #B4B4B4;
                padding: 0px 30px;
                box-sizing: border-box;
            }
            .query-list {
                padding: 10px;
                padding-right: 20px;
                box-sizing: border-box;
                border-left: 0.5px solid #B4B4B4;

                .title {
                    font-weight: 700;
                }

                .label {
                    font-weight: 700;
                    color: gray;
                }
            }
        }

        .col {
            width: 50%;
            overflow: auto;
            float: left;

            .form-container {
                box-shadow: 4px 4px 4px 0px rgb(0 0 0 / 10%);
                padding: 10px;
                box-sizing: border-box;
                background: #edf1eb;
                border-radius: 5px;
                margin: 5px;
                margin-bottom: 10px;

                h3 {
                    margin-left: 10px;
                    color: #777e85;
                }
            }
        }
    }

    .collpased {
        display: flex;
        // background: #e2dee2;
        border-radius: 3px;
        cursor: pointer;
        box-shadow: 4px 0px 4px 0px rgb(0 0 0 / 10%);
        border: 1px solid #B4B4B4;
        .collapsed-title {
            flex: 1;
            font-weight: 700;
            font-size: 20px;
            display: flex;
            align-items: center;
        }
    }

    .execute-btn-container {
        padding: 10px;
        overflow: auto;
        width: 100%;
        box-sizing: border-box;
        border-top: 1px solid #B4B4B4;
    }
}