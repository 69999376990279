.reference-data {
    width: 20%;
    transition: 0.5s;
    margin: 10px;
    padding: 15px;
    background: #1b1919;
    color: #f1e1e1;
    box-sizing: border-box;
    overflow: auto;

    .close {
        float: right;
        cursor: pointer;
    }
    .key-value{
        padding: 7px 0px;
        border-bottom: 0.5px solid rgba(245, 245, 245, 0.555);
        .key{
            font-weight: 700;
            color: #93b2cd;
            word-break: break-all;
        }
        .value{
            margin-top: 3px;
            word-break: break-all;
        }
    }
    .collapsed-container {
        display: flex;
        align-items: center;
        flex-direction: column;
        cursor: pointer;
        .collapse-text {
            writing-mode: vertical-rl;
            text-orientation: mixed;
            font-size: 12px;
            margin-top: 10px;
        }
    }
}