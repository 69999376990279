
.dynamic-form {
    margin: 10px;
    overflow: auto;
    padding: 20px;
    box-sizing: border-box;
    background: aliceblue;
    border-radius: 5px;
    box-shadow: 4px 4px 4px 0px rgb(0 0 0 / 10%);
    .form-title{
        margin-top: 0px;
        margin-bottom: 10px;
        font-weight: 400;
        font-size: 12px;
        color: gray;
    }
}