.component-wrapper {
    padding: 0px 20px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    height: 90vh;
    box-sizing: border-box;
    margin-left: 5px;
    background-color: #FFF;
    h1{
        margin-left: 20px;
    }

    .common-error {
        width: 80vw;
        word-break: break-word;
        background: #ea8b8b70;
        padding: 5px;
        border: 1px solid #9c4242;
        border-radius: 5px;
        font-size: 12px;
    }

    .children {
        flex: 1;
        overflow: auto;
    }
}