.trail-details-section {
    margin: 20px;
    box-shadow: 4px 4px 4px 0px rgb(0 0 0 / 20%);
    background: rgb(230 233 233);
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 0px;
    .item-row {
        margin-bottom: 5px;
        display: flex;
        .key {
            font-weight: 700;
            min-width: 100px;
        }
    }
}
.trail-diff{
    padding: 20px;
    box-sizing: border-box;
}