.tabs-style{
    color:black;
    height: 50px;
    // background-color: yellow;
    // padding-left: -20px;
    width: fit-content;
    margin-left: -10px;
    .tab-text-style{
        text-transform: capitalize;
       
        
    }
}


.active-tab{
    color:white;
    background-color: #b23eb4;
    border-radius: 20px;
    width: 25px;
    height:15px;
    text-align:center ;
    font-size: 12px;
    padding: 4px 8px;
}

.inactive-tab{
    color:white;
    background-color: gray;
    border-radius: 20px;
    width: 25px;
    height:15px;
    text-align:center ;
    font-size: 12px;
    padding: 4px 8px;
}

.label-span{
    margin-left: 1em;
}