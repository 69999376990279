.execute-fix {
    display: flex;
    height: 100%;

    .execute-fix-form-section {
        flex: 1;
            overflow: auto;
            display: flex;
            flex-direction: column;
        .fix-details {
            overflow: auto;
            padding: 10px;
            flex: 1;
        }
    }

   
    .execute-fix-confirm{
        width: 300px;
        margin: 25px;
        span{
            color: red;
        }
    }
}
.confirm-Input{
    label{
        font-size: 15px;
    }
    input{
        width:75px;
    }
}