.field {
    margin-bottom: 12px;

    .label {
        font-size: 12px;
        font-weight: 500;
        color: #464343;
        margin: 4px 0px;
        display: flex;
        flex-direction: row;
    }
    .required::after {
        content:" *";
        color: red;
      }
      ::placeholder{
        color: #d3d3d3;
      }

    input {
        width: 100%;
        box-sizing: border-box;
        border-color: rgb(0 0 0 / 25%);
        border-radius: 5px;
        border-width: 0.5px;
        padding: 10px 5px;
        outline: none;
        font-size: 12px;
        background: #ffffff00;
    }
}

.field-1 {
    margin-bottom: 12px;
    display: flex;

    label {
        font-size: 12px;
        width: 120px;
        display: flex;
        align-items: center;
        font-weight: 500;
        color: #464343;
        margin: 4px 0px;


    }
    .required:after {
        content:" *";
        color: red;
      }
    .input-conatiner-2{
        width: 100%;
        input {
            width: 100%;
            box-sizing: border-box;
            border-color: rgb(0 0 0 / 25%);
            border-radius: 5px;
            border-width: 0.5px;
            padding: 10px 5px;
            background: #ffffff00;
            outline: none;
            font-size: 12px;
        }
    }
}